// Profile.js
import React, { useState, useEffect } from "react";
import { supabase } from "../lib/supabaseClient";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/solid";

const Profile = () => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [newPassword, setNewPassword] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUserProfile = async () => {
			const {
				data: { user },
				error,
			} = await supabase.auth.getUser();

			if (error) {
				toast.error("Error fetching user details: " + error.message);
			} else {
				setUser(user);
			}
			setLoading(false);
		};

		fetchUserProfile();
	}, []);

	const handleUpdatePassword = async () => {
		if (newPassword.trim() === "") {
			toast.error("Password cannot be empty.");
			return;
		}

		setLoading(true);
		const { error } = await supabase.auth.updateUser({ password: newPassword });

		if (error) {
			toast.error("Error updating password: " + error.message);
		} else {
			toast.success(
				"Password updated successfully. You will be logged out to reauthenticate."
			);
			await supabase.auth.signOut();
			navigate("/auth");
		}
		setLoading(false);
	};

	if (loading) {
		return (
			<div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
				<ClipLoader size={60} color="#4F46E5" />
			</div>
		);
	}

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
			<div className="w-full max-w-md p-8 space-y-8 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
				<div className="flex items-center justify-between mb-6">
					<h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
						Your Profile
					</h2>
					<button
						onClick={() => navigate("/settings")}
						className="p-2 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-100 focus:outline-none"
						aria-label="Settings"
					>
						<PencilIcon className="h-6 w-6" />
					</button>
				</div>

				{user ? (
					<div className="space-y-6">
						{/* User Information */}
						<div className="space-y-4">
							{/* Name */}
							<div>
								<label className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-1">
									Name
								</label>
								<p className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200">
									{user.user_metadata?.full_name || "N/A"}
								</p>
							</div>

							{/* Email */}
							<div>
								<label className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-1">
									Email
								</label>
								<p className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200">
									{user.email}
								</p>
							</div>
						</div>

						{/* Update Password */}
						<div className="space-y-2">
							<label className="block text-gray-700 dark:text-gray-300 text-sm font-semibold">
								Update Password
							</label>
							<input
								type="password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder="New Password"
								className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
							<button
								onClick={handleUpdatePassword}
								className="w-full py-2 font-semibold text-white bg-blue-500 dark:bg-blue-600 rounded hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
								disabled={loading}
							>
								{loading ? <ClipLoader size={20} color="#ffffff" /> : "Update Password"}
							</button>
						</div>
					</div>
				) : (
					<p className="text-center text-gray-700 dark:text-gray-300">
						No user details found.
					</p>
				)}
			</div>
		</div>
	);
};

export default Profile;
