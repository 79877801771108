// TaskList.js
import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/supabaseClient";
import TaskDetail from "./TaskDetail";
import NoTasks from "./NoTasks";
import TaskModal from "./TaskModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { PlusIcon, FilterIcon } from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

const TaskList = () => {
	const [tasks, setTasks] = useState([]);
	const [selectedTask, setSelectedTask] = useState(null);
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [shownNotifications, setShownNotifications] = useState(new Set());
	const [notificationChecked, setNotificationChecked] = useState(false);
	const [taskCategories, setTaskCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [selectedDueDate, setSelectedDueDate] = useState("");
	const [filterType, setFilterType] = useState("");
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true); // Reintroduced loading state

	// Fetch tasks from Supabase
	const fetchTasks = useCallback(
		async (selectLastTask = false) => {
			setIsLoading(true); // Start loading
			const {
				data: { user },
			} = await supabase.auth.getUser();

			if (!user) {
				console.error("User not authenticated");
				toast.error("User not authenticated");
				setIsLoading(false); // Stop loading
				return;
			}

			const { data, error } = await supabase
				.from("tasks")
				.select("*")
				.eq("user_id", user.id)
				.order("created_at", { ascending: false });

			if (error) {
				console.error("Error fetching tasks:", error);
				toast.error("Failed to fetch tasks.");
			} else {
				setTasks(data);

				// Collect unique categories from tasks
				const categories = [...new Set(data.flatMap((task) => task.categories || []))];
				setTaskCategories(categories);

				if (data.length > 0) {
					const savedTaskId = localStorage.getItem("selectedTaskId");
					const taskIdToHighlight = location.state?.highlightTaskId;
					let selected =
						taskIdToHighlight && data.find((task) => task.id === taskIdToHighlight);

					if (!selected && savedTaskId) {
						selected = data.find((task) => task.id === parseInt(savedTaskId));
					}
					if (!selected || selectLastTask) {
						selected = data[0];
						localStorage.setItem("selectedTaskId", selected.id);
					}
					setSelectedTask(selected);
				} else {
					setSelectedTask(null);
				}
			}
			setIsLoading(false); // Stop loading after fetching
		},
		[location.state?.highlightTaskId]
	);

	// Check for due or overdue tasks and show notifications
	const checkForDueOrOverdueTasks = useCallback(() => {
		if (notificationChecked) return;

		const today = new Date();
		today.setHours(0, 0, 0, 0); // Ensure the time component is removed

		let dueTodayTasks = [];
		let overdueTasks = [];

		tasks.forEach((task) => {
			if (shownNotifications.has(task.id)) return;

			const taskDueDate = new Date(task.due_date);
			taskDueDate.setHours(0, 0, 0, 0); // Remove the time component for accurate comparison

			const isOverdue = taskDueDate < today && task.status !== "done";
			const isDueToday = taskDueDate.getTime() === today.getTime() && task.status !== "done";

			if (isDueToday) {
				dueTodayTasks.push(task.title);
				setShownNotifications((prev) => new Set(prev).add(task.id));
			} else if (isOverdue) {
				overdueTasks.push(task.title);
				setShownNotifications((prev) => new Set(prev).add(task.id));
			}
		});

		if (dueTodayTasks.length > 0) {
			toast.info(`You have ${dueTodayTasks.length} task(s) due today`);
		}

		if (overdueTasks.length > 0) {
			toast.warning(`You have ${overdueTasks.length} overdue task(s)`);
		}

		setNotificationChecked(true);
	}, [tasks, shownNotifications, notificationChecked]);

	useEffect(() => {
		fetchTasks();
	}, [fetchTasks]);

	useEffect(() => {
		if (tasks.length > 0) {
			checkForDueOrOverdueTasks();
		}
	}, [tasks, checkForDueOrOverdueTasks]);

	// Handle task selection
	const handleTaskClick = (task) => {
		setSelectedTask(task);
		localStorage.setItem("selectedTaskId", task.id);
	};

	// Open and close Task Modal
	const handleOpenTaskModal = () => {
		setIsTaskModalOpen(true);
	};

	const handleCloseTaskModal = () => {
		setIsTaskModalOpen(false);
		fetchTasks(true); // Refresh tasks and select the newly created task
	};

	// Handle filter changes
	const handleFilterTypeChange = (e) => {
		setFilterType(e.target.value);
		setSelectedCategory("");
		setSelectedStatus("");
		setSelectedDueDate("");
	};

	const handleCategoryChange = (e) => {
		setSelectedCategory(e.target.value);
	};

	const handleStatusChange = (e) => {
		setSelectedStatus(e.target.value);
	};

	const handleDueDateChange = (e) => {
		setSelectedDueDate(e.target.value);
	};

	// Filter tasks based on selected filter
	const filteredTasks = tasks.filter((task) => {
		if (filterType === "category") {
			return selectedCategory === "" || (task.categories && task.categories.includes(selectedCategory));
		} else if (filterType === "status") {
			return selectedStatus === "" || task.status === selectedStatus;
		} else if (filterType === "due_date") {
			return selectedDueDate === "" || task.due_date === selectedDueDate;
		} else {
			return true; // Show all tasks if no filter is selected
		}
	});

	// Render Loading state
	if (isLoading) {
		return (
			<div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 z-50">
				<ClipLoader size={60} color="#4F46E5" />
			</div>
		); // Show loading spinner while fetching data
	}

	// Render No Tasks state
	if (tasks.length === 0) {
		return (
			<>
				<NoTasks onCreateFirstTask={handleOpenTaskModal} />
				<TaskModal
					isOpen={isTaskModalOpen}
					onClose={handleCloseTaskModal}
					fetchTasks={fetchTasks}
				/>
			</>
		);
	}

	return (
		<div className="flex flex-col md:flex-row min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-300">
			{/* Task List Sidebar */}
			<div className="w-full md:w-1/3 p-6 bg-white dark:bg-gray-800 shadow-lg">
				<div className="flex flex-col gap-6 mb-6">
					<div className="flex items-center justify-between">
						<h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">Your Tasks</h2>
						<button
							onClick={handleOpenTaskModal}
							className="flex items-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
							aria-label="Add New Task"
						>
							<PlusIcon className="h-5 w-5 mr-2" />
							Add Task
						</button>
					</div>

					{/* Filter Controls */}
					<div className="flex flex-col md:flex-row md:items-center gap-4">
						<div className="relative">
							<FilterIcon className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
							<select
								value={filterType}
								onChange={handleFilterTypeChange}
								className="pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="">No Filter</option>
								<option value="category">Filter by Category</option>
								<option value="status">Filter by Status</option>
								<option value="due_date">Filter by Due Date</option>
							</select>
						</div>

						{/* Conditionally Render Filter Input */}
						{filterType === "category" && (
							<select
								value={selectedCategory}
								onChange={handleCategoryChange}
								className="w-full md:w-auto px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="">All Categories</option>
								{taskCategories.map((category, index) => (
									<option key={index} value={category}>
										{category}
									</option>
								))}
							</select>
						)}

						{filterType === "status" && (
							<select
								value={selectedStatus}
								onChange={handleStatusChange}
								className="w-full md:w-auto px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="">All Statuses</option>
								<option value="to_do">To Do</option>
								<option value="in_progress">In Progress</option>
								<option value="done">Done</option>
							</select>
						)}

						{filterType === "due_date" && (
							<input
								type="date"
								value={selectedDueDate}
								onChange={handleDueDateChange}
								className="px-4 py-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						)}
					</div>
				</div>

				{/* Task List */}
				<ul className="space-y-4">
					{filteredTasks.map((task) => (
						<li
							key={task.id}
							onClick={() => handleTaskClick(task)}
							className={`cursor-pointer p-4 rounded-lg shadow-md flex items-center transition-colors duration-200 ${
								selectedTask && selectedTask.id === task.id
									? "bg-blue-100 dark:bg-blue-800"
									: "bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
							}`}
							aria-selected={selectedTask && selectedTask.id === task.id}
						>
							{/* Priority Indicator */}
							<div
								className={`w-3 h-3 rounded-full mr-4 flex-shrink-0 ${
									task.priority === "low"
										? "bg-green-500"
										: task.priority === "medium"
											? "bg-yellow-500"
											: "bg-red-500"
								}`}
								aria-label={`Priority: ${task.priority}`}
							></div>

							{/* Task Info */}
							<div className="flex-1">
								<p className="font-semibold text-lg">{task.title}</p>
							</div>
						</li>
					))}
				</ul>
			</div>

			{/* Task Detail Section */}
			<div className="w-full md:w-2/3 p-6">
				{selectedTask && <TaskDetail task={selectedTask} fetchTasks={fetchTasks} />}
			</div>

			{/* Task Modal */}
			<TaskModal
				isOpen={isTaskModalOpen}
				onClose={handleCloseTaskModal}
				fetchTasks={fetchTasks}
			/>
		</div>
	);
};

export default TaskList;
