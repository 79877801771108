// ActivityLog.js
import React, { useEffect, useState, useCallback } from "react";
import { supabase } from "../lib/supabaseClient";
import { formatDistanceToNow } from "date-fns";
import {
	PlusCircleIcon,
	PencilAltIcon,
	TrashIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	ClipboardListIcon,
	RefreshIcon,
	CheckCircleIcon,
} from "@heroicons/react/solid";
import ClipLoader from "react-spinners/ClipLoader"; // Ensure you have this installed

const actionIcons = {
	created: {
		icon: PlusCircleIcon,
		color: "text-green-500",
		label: "Created",
	},
	updated: {
		icon: PencilAltIcon,
		color: "text-blue-500",
		label: "Updated",
	},
	deleted: {
		icon: TrashIcon,
		color: "text-red-500",
		label: "Deleted",
	},
	to_do: {
		icon: ClipboardListIcon,
		color: "text-yellow-500",
		label: "To Do",
	},
	in_progress: {
		icon: RefreshIcon,
		color: "text-indigo-500",
		label: "In Progress",
	},
	done: {
		icon: CheckCircleIcon,
		color: "text-green-500",
		label: "Done",
	},
	// Add more actions here if needed
};

const statusLabels = {
	to_do: "To Do",
	in_progress: "In Progress",
	done: "Done",
};

const ActivityLog = ({ entityType, entityId }) => {
	const [activityLogs, setActivityLogs] = useState([]);
	const [isExpanded, setIsExpanded] = useState(false); // State to manage collapse/expand
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false); // State to manage loading

	const fetchActivityLogs = useCallback(async () => {
		setError(null);
		setIsLoading(true); // Start loading
		const { data, error } = await supabase
			.from("activity_logs")
			.select("*")
			.eq("entity_type", entityType)
			.eq("entity_id", entityId)
			.order("created_at", { ascending: false });

		if (error) {
			console.error("Error fetching activity logs:", error);
			setError("Failed to load activity logs.");
		} else {
			setActivityLogs(data);
		}
		setIsLoading(false); // End loading
	}, [entityType, entityId]);

	useEffect(() => {
		if (isExpanded) {
			fetchActivityLogs();
		}
	}, [isExpanded, fetchActivityLogs]);

	const formatActionMessage = (log) => {
		if (log.action === "updated" && log.field_name === "description") {
			return (
				<>
        <span className="font-semibold text-blue-600 dark:text-blue-400">
          {log.field_name}
        </span>{" "}
					changed:
					<div className="mt-2">
						<div>
            <span className="font-medium text-gray-700 dark:text-gray-300">
              Old Value:
            </span>
							<pre className="bg-gray-100 dark:bg-gray-700 p-2 rounded whitespace-pre-wrap mt-1">
              {log.old_value}
            </pre>
						</div>
						<div className="mt-2">
            <span className="font-medium text-green-700 dark:text-green-300">
              New Value:
            </span>
							<pre className="bg-gray-100 dark:bg-gray-700 p-2 rounded whitespace-pre-wrap mt-1">
              {log.new_value}
            </pre>
						</div>
					</div>
				</>
			);
		}

		switch (log.action) {
			case "updated":
				return (
					<>
            <span className="font-semibold text-blue-600 dark:text-blue-400">
              {log.field_name}
            </span>{" "}
						changed from{" "}
						<span className="font-medium text-gray-700 dark:text-gray-300">
              "{log.old_value}"
            </span>{" "}
						to{" "}
						<span className="font-medium text-green-700 dark:text-green-300">
              "{log.new_value}"
            </span>
					</>
				);
			case "created":
				return <span className="font-semibold text-green-600 dark:text-green-400">Created</span>;
			case "deleted":
				return <span className="font-semibold text-red-600 dark:text-red-400">Deleted</span>;
			case "to_do":
			case "in_progress":
			case "done":
				const statusLabel = statusLabels[log.action] || log.action;
				return <span className="font-semibold">{statusLabel}</span>;
			default:
				return log.action;
		}
	};

	const getActionIcon = (action) => {
		const actionDetail = actionIcons[action];
		if (!actionDetail) {
			return (
				<div className="flex-shrink-0">
					<span className="inline-block h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-600"></span>
				</div>
			);
		}
		const IconComponent = actionDetail.icon;
		return (
			<div className="flex-shrink-0">
				<IconComponent className={`h-8 w-8 ${actionDetail.color}`} aria-hidden="true" />
			</div>
		);
	};

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className="mt-6 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
			<button
				onClick={toggleExpand}
				className="w-full flex items-center justify-between px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
				aria-expanded={isExpanded}
				aria-controls={`activity-log-${entityId}`}
			>
				<h4 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Activity Log</h4>
				{isExpanded ? (
					<ChevronUpIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
				) : (
					<ChevronDownIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
				)}
			</button>
			{isExpanded && (
				<div
					className="px-4 py-3 border-t border-gray-200 dark:border-gray-700"
					id={`activity-log-${entityId}`}
					role="region"
					aria-labelledby={`activity-log-heading-${entityId}`}
				>
					{isLoading ? (
						<div className="flex justify-center items-center py-4">
							<ClipLoader size={30} color="#4F46E5" />
							<span className="ml-2 text-gray-600 dark:text-gray-300">Loading...</span>
						</div>
					) : error ? (
						<p className="text-center text-red-500 dark:text-red-400">{error}</p>
					) : activityLogs.length === 0 ? (
						<p className="text-center text-gray-500 dark:text-gray-400">No activity yet.</p>
					) : (
						<ul className="space-y-4">
							{activityLogs.map((log) => (
								<li
									key={log.id}
									className="flex items-start space-x-3 bg-gray-50 dark:bg-gray-700 p-4 rounded-md"
								>
									{getActionIcon(log.action)}
									<div className="flex-1">
										<div className="flex justify-between items-center">
											<h5 className="text-md font-medium text-gray-800 dark:text-gray-100">
												{log.user_email}
											</h5>
											<span className="text-xs text-gray-500 dark:text-gray-400">
                        {formatDistanceToNow(new Date(log.created_at), {
							addSuffix: true,
						})}
                      </span>
										</div>
										<p className="mt-1 text-sm text-gray-700 dark:text-gray-300">
											{formatActionMessage(log)}
										</p>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</div>
	);
};

export default ActivityLog;
