// Navbar.js
import React, { useEffect, useState, useCallback } from "react";
import Switch from "react-switch";
import { useDarkMode } from "../context/DarkModeContext";
import { useNavigate } from "react-router-dom";
import { supabase } from "../lib/supabaseClient";
import {
	MdMinimize,
	MdClose,
	MdCropSquare,
	MdMenu,
	MdSearch,
} from "react-icons/md";
import { toast } from "react-toastify";
import Highlighter from "react-highlight-words";
import FocusTrap from "focus-trap-react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

let ipcRenderer;
let remote;
const isElectron =
	typeof window !== "undefined" &&
	window.process &&
	window.process.type === "renderer";

if (isElectron) {
	ipcRenderer = window.require("electron").ipcRenderer;
	remote = window.require("@electron/remote");
}

const Navbar = ({ onOpenTaskModal }) => {
	const [session, setSession] = useState(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const navigate = useNavigate();
	const { isDarkMode, toggleDarkMode } = useDarkMode();

	useEffect(() => {
		if (ipcRenderer) {
			ipcRenderer.on("update_available", () => {
				toast.info("Update available! Downloading now...");
			});

			ipcRenderer.on("update_downloaded", () => {
				toast.success("Update downloaded! It will be installed on restart.");
				ipcRenderer.removeAllListeners("update_available");
				ipcRenderer.removeAllListeners("update_downloaded");
			});
		}

		return () => {
			if (ipcRenderer) {
				ipcRenderer.removeAllListeners("update_available");
				ipcRenderer.removeAllListeners("update_downloaded");
			}
		};
	}, []);

	useEffect(() => {
		const fetchSession = async () => {
			const {
				data: { session },
			} = await supabase.auth.getSession();
			if (session && session.user.last_sign_in_at !== null) {
				setSession(session);
			} else {
				setSession(null);
			}
		};

		fetchSession();

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			if (session && session.user.last_sign_in_at !== null) {
				setSession(session);
			} else {
				setSession(null);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const debouncedSearch = useCallback(
		debounce(async (term) => {
			if (term.trim() !== "") {
				const { data, error } = await supabase
					.from("tasks")
					.select("id, title")
					.ilike("title", `%${term}%`);

				if (error) {
					console.error("Error searching tasks:", error);
					toast.error("Error searching tasks.");
				} else {
					setSearchResults(data);
				}
			} else {
				setSearchResults([]);
			}
		}, 300), // 300ms debounce

		[]
	);

	const handleSearch = (e) => {
		const term = e.target.value;
		setSearchTerm(term);
		debouncedSearch(term);
	};

	const handleSearchClick = (taskId) => {
		navigate(`/tasks`, { state: { highlightTaskId: taskId } });
		setSearchResults([]);
		setSearchTerm("");
		setIsMobileMenuOpen(false); // Close mobile menu if open
	};

	const handleLogout = async () => {
		await supabase.auth.signOut();
		setSession(null);
		localStorage.clear();
		navigate("/auth");
		toast.success("Logged out successfully.");
	};

	const handleLogin = () => {
		navigate("/auth");
	};

	const handleMinimize = () => {
		if (remote) remote.getCurrentWindow().minimize();
	};

	const handleMaximizeToggle = () => {
		if (remote) {
			const win = remote.getCurrentWindow();
			win.isMaximized() ? win.unmaximize() : win.maximize();
		}
	};

	const handleClose = () => {
		if (remote) remote.getCurrentWindow().close();
	};

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<div className="flex flex-col">
			{/* Electron Header Bar */}
			{isElectron && (
				<div
					className="bg-indigo-600 h-8 flex justify-between items-center px-4 text-gray-300"
					style={{ WebkitAppRegion: "drag" }}
				>
					<div
						className="text-sm absolute left-0 pl-2"
						style={{ WebkitAppRegion: "no-drag" }}
					>
						SwiftTasks
					</div>
					<div className="flex space-x-2 ml-auto">
						<button
							onClick={handleMinimize}
							className="hover:bg-gray-700 p-1 rounded"
							style={{ WebkitAppRegion: "no-drag" }}
							aria-label="Minimize"
						>
							<MdMinimize size={16} />
						</button>
						<button
							onClick={handleMaximizeToggle}
							className="hover:bg-gray-700 p-1 rounded"
							style={{ WebkitAppRegion: "no-drag" }}
							aria-label="Maximize/Restore"
						>
							<MdCropSquare size={16} />
						</button>
						<button
							onClick={handleClose}
							className="hover:bg-red-600 p-1 rounded"
							style={{ WebkitAppRegion: "no-drag" }}
							aria-label="Close"
						>
							<MdClose size={16} />
						</button>
					</div>
				</div>
			)}

			{/* Main Navbar */}
			<nav
				className={`shadow-lg flex items-center justify-between px-6 py-4 bg-white dark:bg-gray-800`}
			>
				{/* Logo or App Name */}
				<div className="flex items-center">
					<Link to="/" className="text-indigo-600 dark:text-indigo-400 text-xl md:text-2xl font-bold">
						SwiftTasks
					</Link>
				</div>


				{/* Desktop Menu */}
				<div className="hidden lg:flex items-center space-x-6">
					{session ? (
						<>
							<button
								onClick={() => navigate("/profile")}
								className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors font-medium"
							>
								Profile
							</button>
							<button
								onClick={() => navigate("/tasks")}
								className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors font-medium"
							>
								View Tasks
							</button>
							<button
								onClick={() => {
									onOpenTaskModal();
									setIsMobileMenuOpen(false);
								}}
								className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors font-medium"
							>
								Create Task
							</button>
							<button
								onClick={handleLogout}
								className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors font-medium"
							>
								Logout
							</button>
						</>
					) : (
						<button
							onClick={handleLogin}
							className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors font-medium"
						>
							Login
						</button>
					)}
				</div>

				{/* Search and Dark Mode */}
				<div className="flex items-center space-x-4">
					{session && (
						<div className="relative">
							{/* Search Input */}
							<input
								type="text"
								value={searchTerm}
								onChange={handleSearch}
								placeholder="Search tasks..."
								className="hidden lg:block w-48 lg:w-64 px-3 py-2 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
							/>
							<MdSearch
								className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400 hidden lg:block"
								size={20}/>

							{/* Mobile Search Input */}
							<input
								type="text"
								value={searchTerm}
								onChange={handleSearch}
								placeholder="Search..."
								className="block lg:hidden w-full px-3 py-2 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
							/>
							<MdSearch
								className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400 block lg:hidden"
								size={20}/>

							{/* Search Results Dropdown */}
							{searchResults.length > 0 && (
								<ul className="absolute left-0 mt-2 w-full bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md shadow-lg max-h-60 overflow-y-auto z-50">
									{searchResults.map((result) => (
										<li
											key={result.id}
											onClick={() => handleSearchClick(result.id)}
											className="px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer text-gray-800 dark:text-gray-300"
										>
											<Highlighter
												highlightClassName="bg-yellow-200 dark:bg-yellow-700"
												searchWords={[searchTerm]}
												autoEscape={true}
												textToHighlight={result.title}
											/>
										</li>
									))}
								</ul>
							)}
						</div>
					)}

					{/* Dark Mode Toggle */}
					<Switch
						onChange={toggleDarkMode}
						checked={isDarkMode}
						offColor="#a0aec0"
						onColor="#4F46E5"
						uncheckedIcon={false}
						checkedIcon={false}
						height={20}
						width={48}
						aria-label="Toggle Dark Mode"
					/>

					{/* Mobile Menu Toggle */}
					<div className="lg:hidden">
						<button
							onClick={toggleMobileMenu}
							className="text-indigo-600 dark:text-indigo-400 focus:outline-none"
							aria-label="Toggle Menu"
						>
							{isMobileMenuOpen ? <MdClose size={28}/> : <MdMenu size={28}/>}
						</button>
					</div>
				</div>
			</nav>

			{/* Mobile Menu */}
			{isMobileMenuOpen && (
				<FocusTrap>
					<div className="fixed inset-0 flex z-50">
						<div
							className="fixed inset-0 bg-black opacity-50"
							onClick={toggleMobileMenu}
							aria-hidden="true"
						></div>
						<div
							className="relative flex flex-col w-64 bg-white dark:bg-gray-800 p-6 space-y-8 transform transition-transform duration-300 ease-in-out"
							style={{ WebkitAppRegion: "no-drag" }}
						>
							{/* Close Button */}
							<button
								onClick={toggleMobileMenu}
								className="self-end text-indigo-600 dark:text-indigo-400 focus:outline-none"
								aria-label="Close Menu"
							>
								<MdClose size={28} />
							</button>

							{/* Menu Items */}
							{session ? (
								<div className="flex flex-col space-y-6">
									<button
										onClick={() => {
											navigate("/profile");
											toggleMobileMenu();
										}}
										className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 text-lg font-medium transition-colors text-left"
									>
										Profile
									</button>
									<button
										onClick={() => {
											navigate("/tasks");
											toggleMobileMenu();
										}}
										className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 text-lg font-medium transition-colors text-left"
									>
										View Tasks
									</button>
									<button
										onClick={() => {
											onOpenTaskModal();
											toggleMobileMenu();
										}}
										className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 text-lg font-medium transition-colors text-left"
									>
										Create Task
									</button>
									<button
										onClick={() => {
											handleLogout();
											toggleMobileMenu();
										}}
										className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 text-lg font-medium transition-colors text-left"
									>
										Logout
									</button>
								</div>
							) : (
								<button
									onClick={() => {
										handleLogin();
										toggleMobileMenu();
									}}
									className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 text-lg font-medium transition-colors text-left"
								>
									Login
								</button>
							)}
						</div>
					</div>
				</FocusTrap>
			)}
		</div>
	);
};

export default Navbar;
