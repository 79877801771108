// TaskDetail.js
import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/supabaseClient";
import SubtaskList from "./SubtaskList";
import TaskModal from "./TaskModal";
import { toast } from "react-toastify";
import { formatStatus } from "../utils";
import SubtaskModal from "./SubtaskModal";
import ActivityLog from "./ActivityLog";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

const TaskDetail = ({ task, fetchTasks }) => {
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);
	const [newSubtaskTitle, setNewSubtaskTitle] = useState("");
	const [subtasks, setSubtasks] = useState([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isLoadingSubtasks, setIsLoadingSubtasks] = useState(true); // Loading state for subtasks

	const formattedDueDate = new Date(task.due_date).toLocaleDateString('en-GB');

	// Fetch subtasks related to the task
	const fetchSubtasks = useCallback(async () => {
		setIsLoadingSubtasks(true); // Start loading subtasks
		const { data, error } = await supabase
			.from("subtasks")
			.select("*")
			.eq("parent_task_id", task.id)
			.order("created_at", { ascending: true });

		if (error) {
			console.error("Error fetching subtasks:", error);
			toast.error("Failed to fetch subtasks.");
		} else {
			setSubtasks(data);
		}
		setIsLoadingSubtasks(false); // Stop loading subtasks
	}, [task.id]);

	useEffect(() => {
		fetchSubtasks();
	}, [fetchSubtasks]);

	// Open and close Task Modal
	const handleOpenTaskModal = () => {
		setIsTaskModalOpen(true);
	};

	const handleCloseTaskModal = () => {
		setIsTaskModalOpen(false);
		fetchTasks(); // Refresh tasks after closing the modal
	};

	// Open and close Subtask Modal
	const handleOpenSubtaskModal = () => {
		setIsSubtaskModalOpen(true);
	};

	const handleCloseSubtaskModal = () => {
		setIsSubtaskModalOpen(false);
		fetchSubtasks();
	};

	// Handle quick creation of a subtask
	const handleQuickCreateSubtask = async (e) => {
		if (e.key === "Enter" && newSubtaskTitle.trim() !== "") {
			try {
				const {
					data: { user },
				} = await supabase.auth.getUser();
				if (!user) {
					toast.error("User not authenticated");
					return;
				}

				const subtaskData = {
					title: newSubtaskTitle,
					parent_task_id: task.id,
					user_id: user.id,
				};

				const { data, error } = await supabase
					.from("subtasks")
					.insert([subtaskData])
					.select("*")
					.single();

				if (error) {
					console.error("Error creating subtask:", error);
					toast.error("Error creating subtask");
				} else {
					const newSubtask = data;

					// Insert activity log
					const { error: logError } = await supabase.from("activity_logs").insert([
						{
							entity_type: "subtask",
							entity_id: newSubtask.id,
							user_id: user.id,
							user_email: user.email,
							action: "created",
						},
					]);

					if (logError) {
						console.error("Error inserting activity log:", logError);
						toast.error("Error logging activity");
					}

					setNewSubtaskTitle("");
					fetchSubtasks();
					toast.success("Subtask created successfully");
				}
			} catch (error) {
				console.error("Error fetching user:", error);
				toast.error("Error fetching user");
			}
		}
	};

	// Handle task deletion
	const handleDeleteTask = async () => {
		try {
			const {
				data: { user },
			} = await supabase.auth.getUser();

			if (!user) {
				console.error("User not authenticated");
				toast.error("User not authenticated");
				return;
			}

			// Delete all subtasks associated with the task
			const { error: subtaskError } = await supabase
				.from("subtasks")
				.delete()
				.eq("parent_task_id", task.id);

			// Delete the task itself
			const { error: taskError } = await supabase
				.from("tasks")
				.delete()
				.eq("id", task.id);

			if (subtaskError || taskError) {
				toast.error("Error deleting task or subtasks");
				console.error("Error deleting task or subtasks", subtaskError, taskError);
			} else {
				// Record activity log for task deletion
				const { error: logError } = await supabase.from("activity_logs").insert([
					{
						entity_type: "task",
						entity_id: task.id,
						user_id: user.id,
						user_email: user.email,
						action: "deleted",
					},
				]);

				if (logError) {
					console.error("Error inserting activity log:", logError);
					toast.error("Error logging activity");
				}

				toast.success("Task and subtasks deleted successfully");
				fetchTasks();
			}
		} catch (error) {
			console.error("Error deleting task or subtasks", error);
			toast.error("Error deleting task or subtasks");
		} finally {
			setIsDeleteModalOpen(false);
		}
	};

	// Confirm deletion of the task
	const confirmDeleteTask = () => {
		setIsDeleteModalOpen(true);
	};

	// Close the delete confirmation modal
	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	return (
		<div className="flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full overflow-y-auto">
			{/* Task Header */}
			<div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
				<div className="flex items-center">
					{/* Priority Indicator */}
					<div
						className={`w-4 h-4 rounded-full mr-3 ${
							task.priority === "low"
								? "bg-green-500"
								: task.priority === "medium"
									? "bg-yellow-500"
									: "bg-red-500"
						}`}
						aria-label={`Priority: ${task.priority}`}
					></div>
					<h3 className="text-2xl font-bold text-gray-800 dark:text-gray-100">{task.title}</h3>
				</div>
				<div className="flex space-x-3 mt-4 md:mt-0">
					<button
						onClick={handleOpenTaskModal}
						className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
					>
						<PencilAltIcon className="h-5 w-5 mr-2" />
						Edit
					</button>
					<button
						onClick={confirmDeleteTask}
						className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
					>
						<TrashIcon className="h-5 w-5 mr-2" />
						Delete
					</button>
				</div>
			</div>

			{/* Task Details */}
			<div className="space-y-4 mb-6">
				<p className="text-gray-700 dark:text-gray-300 whitespace-pre-wrap">
					{task.description}
				</p>


				<div className="flex flex-col md:flex-row md:space-x-8">
					<div>
						<span className="font-semibold">Due Date:</span>{" "}
						<span className="font-medium">{formattedDueDate}</span>
					</div>
					<div>
						<span className="font-semibold">Status:</span>{" "}
						<span className="font-medium">{formatStatus(task.status)}</span>
					</div>
					<div>
						<span className="font-semibold">Priority:</span>{" "}
						<span
							className={`font-medium ml-2 capitalize ${
								task.priority === "low"
									? "text-green-400"
									: task.priority === "medium"
										? "text-yellow-400"
										: "text-red-400"
							}`}
						>
              {task.priority}
            </span>
					</div>
				</div>

				<div>
					<span className="font-semibold">Categories:</span>{" "}
					{task.categories && task.categories.length > 0 ? task.categories.join(", ") : "None"}
				</div>
			</div>

			{/* Subtasks Section */}
			<div className="mb-6">
				<h4 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Subtasks</h4>
				<div className="flex items-center mb-4">
					<input
						type="text"
						value={newSubtaskTitle}
						onChange={(e) => setNewSubtaskTitle(e.target.value)}
						onKeyDown={handleQuickCreateSubtask}
						placeholder="Quick create subtask"
						className="flex-1 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-l-md bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
					/>
					<button
						onClick={handleOpenSubtaskModal}
						className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
						aria-label="Add Subtask"
					>
						+
					</button>
				</div>
				<div className="max-h-60 overflow-y-auto">
					{isLoadingSubtasks ? (
						<div className="flex justify-center items-center py-4">
							<ClipLoader size={30} color="#4F46E5" />
						</div>
					) : (
						<SubtaskList
							taskId={task.id}
							fetchTasks={fetchTasks}
							subtasks={subtasks}
							fetchSubtasks={fetchSubtasks}
							setSubtasks={setSubtasks}
						/>
					)}
				</div>
			</div>

			{/* Activity Log */}
			<div>
				<ActivityLog entityType="task" entityId={task.id} />
			</div>

			{/* Modals */}
			<TaskModal
				isOpen={isTaskModalOpen}
				onClose={handleCloseTaskModal}
				task={task}
				fetchTasks={fetchTasks}
			/>
			<SubtaskModal
				isOpen={isSubtaskModalOpen}
				onClose={handleCloseSubtaskModal}
				parentTaskId={task.id}
				fetchTasks={fetchTasks}
				fetchSubtasks={fetchSubtasks}
			/>

			{/* Delete Confirmation Modal */}
			{isDeleteModalOpen && (
				<div
					className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
					role="dialog"
					aria-modal="true"
					aria-labelledby="delete-task-title"
				>
					<div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md">
						<h2 id="delete-task-title" className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-100">
							Delete Task
						</h2>
						<p className="text-gray-700 dark:text-gray-300 mb-6">
							Deleting this task will also remove all associated subtasks. Are you sure you want to continue?
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={handleDeleteModalClose}
								className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500"
							>
								Cancel
							</button>
							<button
								onClick={handleDeleteTask}
								className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TaskDetail;
